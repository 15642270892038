






























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { TransferRequestInfoProperties } from "./TransferRequestInfoView.vue";
import DateUtil from '@/utilities/DateUtil';

@Component({})
export default class TransferRequestSummaryView extends Vue {
    @Prop() private properties: TransferRequestInfoProperties;

    public get request() {
        return this.properties.request;
    }

	public get requestBy() {
		return this.request.requestBy;
	}
	
	public get requestByName() {
		const n = this.requestBy.name;
		const u = this.requestByFacebookAccount.uniqueNo ?? null;
		return u !== null ? `${n} [${u}]` : n;
	}

	public get requestByUrl() {
		return `/management/customers/modify?id=${this.requestBy.id}`;
	}

	public get requestByFacebookAccount() {
		return this.requestBy.facebookAccount;
	}

	public get transferTo() {
		return this.request.transferTo;
	}

	public get transferToName() {
		const n = this.transferTo.name;
		const u = this.transferToFacebookAccount.uniqueNo ?? null;
		return u !== null ? `${n} [${u}]` : n;
	}

	public get transferToUrl() {
		return `/management/customers/modify?id=${this.transferTo.id}`;
	}

	public get transferToFacebookAccount() {
		return this.transferTo.facebookAccount;
	}

    public get currency() {
        return this.properties.currency;
    }

    public get totalAmount() {
        return this.request.totalAmount ?? 0;
    }

    public get isProcessing() {
        return this.request.status === "Processing";
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "-";
    }

    public async cancel() {
        await this.properties.events.fire("cancel");
    }
}
